import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { ROOT_DATA_KEY } from 'src/constants';
import uiReducer from 'src/redux/slices/ui';

const rootPersistConfig = {
  key: ROOT_DATA_KEY,
  storage,
  blacklist: ['ui']
};

const uiPersistConfig = {
  key: 'ui',
  storage,
  blacklist: ['modals']
};

const combinedReducers = combineReducers({
  ui: persistReducer(uiPersistConfig, uiReducer),
});

const persistedReducers = persistReducer(rootPersistConfig, combinedReducers);

export const store = configureStore({
  reducer: persistedReducers,
  middleware: (defaultMiddlewares => defaultMiddlewares({
    serializableCheck: false
  }))
});

export const persistor = persistStore(store, {}, function () {
  persistor.persist();
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
