import { USER_TOKEN_KEY } from '@constants';
import { Api } from '@helpers/api';
import {
  CreateUserOptions,
  IAuthApiResponse,
  IUserApiResponse,
  IUsersApiResponse,
  LoginSocialUserOptions,
  LoginUserOptions,
  UpdateUserOptions
} from './types';
import { Cookies } from 'react-cookie';

const cookies = new Cookies();

const USERS_API_BASE = 'users';
const AUTH_API_BASE = `${USERS_API_BASE}/auth`;

/* Utility Helpers */
export const loadAuthToken = (): string => cookies.get(USER_TOKEN_KEY) || null;

export const saveAuthToken = (token: string) => {
  return cookies.set(USER_TOKEN_KEY, token, { path: '/', sameSite: 'strict' });
};

export const removeAuthToken = () => cookies.remove(USER_TOKEN_KEY, { path: '/', sameSite: 'strict' });

export const validateEmail = (email: string) => {
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email.toLowerCase());
};

/* User API Helpers */
export const searchUsers = async (q: string, itemsPage = 1, itemsPerPage = 10) => {
  const res: IUsersApiResponse = await Api.get(`${USERS_API_BASE}/search`, { params: { q, itemsPage, itemsPerPage } });
  return res;
};

export const createUser = async (data: CreateUserOptions) => {
  const res: IUserApiResponse = await Api.post(`${USERS_API_BASE}/`, data);
  return res;
};

export const loginUser = async (data: LoginUserOptions) => {
  const res: IAuthApiResponse = await Api.post(`${AUTH_API_BASE}/local`, data);
  if (res.data.status === 'enabled') {
    saveAuthToken(res.data.token as string);
  }
  return res;
};

export const loginSocialUser = async (service: 'google' | 'facebook', data: LoginSocialUserOptions) => {
  const res: IAuthApiResponse = await Api.post(`${AUTH_API_BASE}/social/${service}`, data);
  if (res.data.status === 'enabled') {
    saveAuthToken(res.data.token as string);
  }
  return res;
};

export const getAuthUser = async (): Promise<IAuthApiResponse> => {
  const res = await Api.get(`${AUTH_API_BASE}/me`);
  return res;
};

export const logoutUser = () => {
  return removeAuthToken();
};

export const updateUser = async (data: UpdateUserOptions) => {
  const res: IUserApiResponse = await Api.put(`${USERS_API_BASE}/me`, data);
  return res;
};

export const updateUserAvatar = async (file: File) => {
  const data = new FormData();
  data.append('avatar', file);
  const config = {
    headers: {
      'content-type': 'multipart/form-data'
    }
  };
  const res: IUserApiResponse = await Api.post(`${USERS_API_BASE}/me/upload`, data, config);
  return res;
};

export const changePassword = async (data: { oldPassword: string; newPassword: string; user: string; }) => {
  const res: IUserApiResponse = await Api.post(`${AUTH_API_BASE}/change-password`, data);
  return res;
};

export const resetPassword = async (data: { newPassword: string; token: string; }) => {
  const res: IUserApiResponse = await Api.post(`${AUTH_API_BASE}/reset-password`, data);
  return res;
};

export const forgotPassword = async (data: { email: string; }) => {
  const res: IUserApiResponse = await Api.post(`${AUTH_API_BASE}/forgot-password`, data);
  return res;
};

export const verifyUser = async (data: { token: string; type: 'email' | 'phone' }) => {
  const { type, ...body } = data;
  const res: IUserApiResponse = await Api.post(`${USERS_API_BASE}/verifications/confirm?type=${type}`, body);
  saveAuthToken(res.data.token as string);
  return res;
};

export const requestUserVerification = async (data: { email: string; resend?: boolean, type: 'email' | 'phone' }) => {
  const { type, resend, ...body } = data;
  const res: IUserApiResponse = await Api.post(`${USERS_API_BASE}/verifications/request?type=${type}${resend ? `&resend=1` : ''}`, body);
  return res;
};
