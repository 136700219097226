import { Observable } from "rxjs";
import { io, Socket } from "socket.io-client";

class SocketHelper {
  public client: Socket;
  constructor(ioClient: typeof io) {
    this.client = ioClient();
  }

  public onConnect(): Observable<void> {
    return new Observable((subscriber) => {
      this.client.on("connect", () => subscriber.next());
    });
  }

  public onDisconnect(): Observable<void> {
    return new Observable((subscriber) => {
      this.client.on("disconnect", () => subscriber.next());
    });
  }

  public on<T>(event: string): Observable<T> {
    return new Observable((subscriber) => {
      this.client.on(event, (data: T) => subscriber.next(data));
    });
  }

  public emit<T, F>(event: string, data: T, cb?: F): void {
    this.client.emit(event, data, cb);
  }
}

const socketHelper = new SocketHelper(io);

export default socketHelper;