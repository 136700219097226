import React from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

export default function Loader({ message }: { message?: string }) {
  return (
    <main id="loader">
      <div className="spinner">
        <div className="ball"></div>
        <p className="text-uppercase">{message || "loading"}</p>
      </div>
    </main>
  );
}

export const ContestCardLoader = () => (
  <div className="contest-card contest-card-loader bg-light p-3 p-md-4 mb-3 mb-md-0">
    <span className="d-flex justify-content-between pb-3 pb-md-4">
      <Skeleton width={60} height={20} borderRadius={12} />
      <Skeleton width={90} height={20} borderRadius={12} />
    </span>
    <div className="pb-4 pb-md-5">
      <Skeleton width="100%" height={200} borderRadius={7} />
    </div>
    <div className="pb-3">
      <Skeleton width="100%" height={20} borderRadius={10} />
    </div>
    <div className="d-flex justify-content-between">
      <Skeleton width={20} height={20} circle={true} />
      <Skeleton width={20} height={20} circle={true} />
      <Skeleton width={20} height={20} circle={true} />
    </div>
  </div>
);

export const ContestCardLoadingGrid = () => (
  <div className="row p-0 m-0">
    <div className="col-lg-4">
      <ContestCardLoader />
    </div>
    <div className="col-lg-4">
      <ContestCardLoader />
    </div>
    <div className="col-lg-4">
      <ContestCardLoader />
    </div>
  </div>
);
