export interface UIState {
  showSideMenu: boolean;
  explore: {
    layout: ExploreLayout;
    enableFilter: boolean;
  };
  redirectUrl?: string;
  modals: {
    [Modals.CreateContest]: ModalProps;
    [Modals.EditContestant]: ModalProps;
    [Modals.Leaderboard]: ModalProps;
    [Modals.ConfirmModal]: ModalProps;
    [Modals.CreateContestRegistrationForm]: ModalProps
    [Modals.Lightbox]: ModalProps
  };
}

export enum ExploreLayout {
  Grid,
  List,
}

export interface ModalProps {
  isOpen: boolean;
  props?: any;
}

export enum Modals {
  CreateContest = "createContest",
  Leaderboard = "leaderboard",
  EditContestant = "editContestant",
  ConfirmModal = "confirmModal",
  CreateContestRegistrationForm = 'createContestRegistrationForm',
  Lightbox = "lightbox"
}

export interface ModalEvent {
  name: Modals;
  props?: any;
}
