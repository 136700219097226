import '@styles/app.scss';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { persistor, store } from '../redux/store';
import Loader from '@components/Loader';
import Router from 'next/router';
import Head from 'next/head';
import { useEffect } from 'react';
import { showToast } from '@helpers/util';
import { watchApiError } from '@helpers/event';
import { closeActiveModals } from '@store/ui';
import { loadAuthToken, removeAuthToken } from '@services/user';
import NProgress from 'nprogress';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import TagManager from "react-gtm-module"
import socket from '@helpers/socket';
import { IUser } from '@services/user/types';
import '@styles/nprogress.scss';

const tagManagerArgs = {
  gtmId: process.env.NEXT_PUBLIC_GTM_ID as string,
}

function MyApp({ Component, pageProps, err }: any) {
  // Setup Google Tag Manager
  useEffect(() => {
    TagManager.initialize(tagManagerArgs)
  }, []);

  // listen to specific api errors and handle accordingly
  useEffect(() => {
    const apiErrorSubscription = watchApiError()
      .subscribe(param => {
        const { code = '', message = '' } = param || {};
        if (['EXPIRED_TOKEN', 'MISSING_TOKEN'].includes(code)) {
          removeAuthToken();
          showToast(message, 'error');
          Router.push(`/auth/login?continue=${encodeURIComponent(Router.asPath)}`);
        }

        if (code === 'USER_NOT_VERIFIED') {
          Router.push('/verify-account');
        }
      });

    return () => apiErrorSubscription.unsubscribe();
  }, []);


  useEffect(() => {
    const delay = 500; // in milliseconds
    let timer: NodeJS.Timeout;

    const loadProgress = () => {
      timer = setTimeout(function () {
        NProgress.start();
      }, delay);
    };

    const stopProgress = () => {
      clearTimeout(timer);
      NProgress.done();
    };

    const handleRouteChangeEnd = () => {
      stopProgress();
    };

    const handleRouteChangeStart = () => {
      // ? close any open modals on page navigate
      store.dispatch(closeActiveModals());
      loadProgress();
    };

    Router.events.on('routeChangeStart', handleRouteChangeStart);
    Router.events.on('routeChangeComplete', handleRouteChangeEnd);
    Router.events.on('routeChangeError', handleRouteChangeEnd);

    // cleanup
    return () => {
      Router.events.off('routeChangeStart', handleRouteChangeStart);
      Router.events.off('routeChangeComplete', handleRouteChangeEnd);
      Router.events.off('routeChangeError', handleRouteChangeEnd);
    };
  }, []);

  useEffect(() => {
    const connectSub = socket.on<void>('connect').subscribe(() => {
      const token = loadAuthToken();
      if (token) {
        socket.emit('authentication', { token }, ({ state, user }: { state: "authorized" | 'unauthorized', user: Partial<IUser> }) => {
          console.log('%s is %s on socket id: %s', user._id, state, socket.client.id);
        });
      }
    });

    return () => {
      connectSub.unsubscribe();
    }
  })

  return <Provider store={store}>
    <PersistGate loading={<Loader></Loader>} persistor={persistor}>
      <Head>
        <meta httpEquiv="Content-Type" content="text/html; charset=UTF-8" />
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
      </Head>
      {/* // Workaround for https://github.com/vercel/next.js/issues/8592 */}
      <Component {...pageProps} err={err} />
      <ToastContainer />
    </PersistGate>
  </Provider>;
}

export default MyApp;
