import { Dispatch, SetStateAction } from "react";
import { toast } from "react-toastify";

export const showToast = (message: string, type: "success" | "error") => {
  return toast[type](message, {
    position: "top-center",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
};

export const wrapPromiseWithLoader = <T extends Record<string, any>>(
  promiseFn: Promise<T>,
  config: { skipToast?: boolean } = { skipToast: false }
): Promise<T> => {
  return config.skipToast
    ? promiseFn
    : toast.promise(promiseFn, {
        pending: "Hold on",
        success: {
          render({ data }: any) {
            return data?.message || "Operation successful";
          },
        },
        error: {
          render({ data }: any) {
            return typeof data === "string" ? data : data?.message || "Operation failed";
          },
        },
      });
};

export const showConfirmDialog = (message: string, callback: (value: any) => void) => {
  // todo: implement confirm dialog
};

/**
 * Generates a random unique alphanumeric characters with a specified length.
 * @param length The length of the chars to generate. Defaults to `6`
 * @param numeric If true, generates only numeric chars. Defaults to `false`
 * */
export const generateRandomID = <T extends boolean>(
  length: number = 6,
  numeric?: T
): T extends true ? number : string => {
  const xters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  const top = Number([1, ...Array(length - 1).fill(0)].join(""));
  const bottom = Number(Array(length).fill(9).join(""));
  const res: any = numeric
    ? Math.ceil(Math.random() * (top - bottom) + bottom)
    : [...Array(length).keys()]
        .map(() => xters.charAt(Math.floor(Math.random() * xters.length)))
        .join("");
  return res;
};
interface MetaTagsProps {
  title: string;
  description: string;
  image?: string;
  link?: string;
  isIndex?: boolean;
}

export const getMetaTags = ({
  title,
  description,
  image,
  link,
  isIndex = false,
}: MetaTagsProps) => {
  title = isIndex ? "Contestish — Host and manage your online contests with ease!" : title;
  link = link ? `${location.origin}/${link}` : location.origin;
  return [
    { elem: "title", content: isIndex ? title : `${title} | Contestish` },
    {
      elem: "meta",
      key: "description",
      name: "description",
      content: description,
    },
    { elem: "meta", key: "image", name: "image", content: image },
    { elem: "meta", key: "itemName", itemProp: "name", content: title },
    {
      elem: "meta",
      key: "itemDescription",
      itemProp: "description",
      content: description,
    },
    { elem: "meta", key: "itemImage", itemProp: "image", content: image },
    { elem: "meta", name: "twitter:card", content: "summary" },
    { elem: "meta", name: "twitter:title", content: title },
    { elem: "meta", name: "twitter:description", content: description },
    { elem: "meta", name: "twitter:image:src", content: image },
    { elem: "meta", name: "og:title", content: title },
    { elem: "meta", name: "og:description", content: description },
    { elem: "meta", name: "og:image", content: image },
    { elem: "meta", name: "og:url", content: link },
    {
      elem: "meta",
      name: "og:site_name",
      content: "Contestish — Host and manage your online contests with ease!",
    },
    { elem: "meta", name: "og:type", content: "website" },
  ];
};

export const copyToClipboard = (link: string, setCopied: Dispatch<SetStateAction<boolean>>) => {
  navigator.clipboard.writeText(link);
  setCopied(true);
  setTimeout(() => {
    setCopied(false);
  }, 3000);
};

export const getTransactionId = (suffix?: string) =>
  `TR_${Date.now()}_${suffix || generateRandomID()}`;

export const formatPrice = (price: string | number) =>
  price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
