import _axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import axiosRetry from 'axios-retry';
import { API_BASE_URL, USER_TOKEN_KEY } from 'src/constants';
import { IApiErrorResponse, IApiResponse } from './types';
import { Cookies } from 'react-cookie';
import { publishApiError } from 'src/helpers/event';
import { wrapPromiseWithLoader } from '@helpers/util';

const cookies = new Cookies();

interface CustomAxiosRequestConfig extends AxiosRequestConfig {
  custom?: {
    skipToast: boolean;
  }
}

const axios = _axios.create({
  baseURL: `${API_BASE_URL}/`,
  headers: {
    'Content-Type': 'application/json'
  }
});

/* Add auth header interceptor */
axios.interceptors.request.use(
  config => {
    const token = cookies.get(USER_TOKEN_KEY);
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  error => Promise.reject(error)
);

// https://github.com/softonic/axios-retry/issues/87
const retryDelay = (retryNumber = 0) => {
  const seconds = Math.pow(2, retryNumber) * 1000;
  const randomMs = 1000 * Math.random();
  return seconds + randomMs;
};

const retryConfig = {
  retries: 2,
  retryDelay,
  // retry on Network Error & 5xx responses
  retryCondition: axiosRetry.isRetryableError,
};

const handleApiSuccess = (res: AxiosResponse) => {
  return res.data;
};

const handleApiError = (err: AxiosError) => {
  let errorMessagge = '';

  // request was manually cancelled in a `useEffect` hook
  if (_axios.isCancel(err)) {
    return; // fail silently
  }

  if (err.response) {
    const apiError: IApiErrorResponse = err.response.data;
    // client received an error response (5xx, 4xx)
    console.error(
      `Backend returned code ${err.code}:${apiError.code}, ` +
      `body was: ${apiError.message}`,
      'data:', apiError.data
    );
    errorMessagge = apiError.message;

    // dispatch api errors as event for use across app
    publishApiError({ code: apiError.code, message: errorMessagge });
  } else if (err.request) {
    // client never received a response, or request never left
    console.error('An error occurred:', err.message);
  } else {
    // anything else
    console.error('Well, that was unexpected:', err.message);
  }

  throw errorMessagge || 'We couldn\'t complete your request. Please try again or check your internet connection.';
};

export const Api = {
  getCancelTokenSource: () => _axios.CancelToken.source(),
  get: (endpoint: string, config?: AxiosRequestConfig): Promise<IApiResponse> =>
    axios.get(endpoint, { 'axios-retry': retryConfig, ...config })
      .then(handleApiSuccess)
      .catch(handleApiError),
  post: (endpoint: string, data: any, config?: CustomAxiosRequestConfig): Promise<IApiResponse> =>
    wrapPromiseWithLoader(
      axios.post(endpoint, data, config)
        .then(handleApiSuccess)
        .catch(handleApiError),
      { ...(config ? config.custom : {}) }
    ),
  put: (endpoint: string, data: any, config?: CustomAxiosRequestConfig): Promise<IApiResponse> =>
    wrapPromiseWithLoader(
      axios.put(endpoint, data, config)
        .then(handleApiSuccess)
        .catch(handleApiError),
      { ...(config ? config.custom : {}) }
    ),
  delete: (endpoint: string, config?: CustomAxiosRequestConfig): Promise<IApiResponse> =>
    wrapPromiseWithLoader(
      axios.delete(endpoint, config)
        .then(handleApiSuccess)
        .catch(handleApiError),
      { ...(config ? config.custom : {}) }
    ),
};
