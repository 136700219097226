import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ExploreLayout, Modals, UIState } from "./types";

const initialState: UIState = {
  showSideMenu: true,
  explore: {
    layout: ExploreLayout.Grid,
    enableFilter: false,
  },
  redirectUrl: undefined,
  modals: {
    [Modals.CreateContest]: { isOpen: false, props: null },
    [Modals.Leaderboard]: { isOpen: false, props: null },
    [Modals.EditContestant]: { isOpen: false, props: null },
    [Modals.ConfirmModal]: { isOpen: false, props: null },
    [Modals.CreateContestRegistrationForm]: { isOpen: false, props: null },
    [Modals.Lightbox]: { isOpen: false, props: null }
  },
};

const uiSlice = createSlice({
  name: "ui",
  initialState,
  reducers: {
    toggleShowSideMenu: (state: UIState, action: PayloadAction<undefined>) => {
      state.showSideMenu = !state.showSideMenu;
    },
    setRedirectUrl: (
      state: UIState,
      action: PayloadAction<string | undefined>
    ) => {
      state.redirectUrl = action.payload;
    },
    toggleExploreLayout: (state: UIState, action: PayloadAction<undefined>) => {
      state.explore.layout =
        state.explore.layout === ExploreLayout.Grid
          ? ExploreLayout.List
          : ExploreLayout.Grid;
    },
    toggleExploreFilter: (state: UIState, action: PayloadAction<undefined>) => {
      state.explore.enableFilter = !state.explore.enableFilter;
    },
    toggleModal: (
      state: UIState,
      action: PayloadAction<{ name: Modals; props?: any }>
    ) => {
      if (state.modals[action.payload.name]) {
        // toggle if modal exists
        state.modals[action.payload.name].isOpen =
          !state.modals[action.payload.name].isOpen;
        state.modals[action.payload.name].props = action.payload.props;
      }
    },
    closeActiveModals: (state: UIState, action: PayloadAction<undefined>) => {
      Object.keys(state.modals).forEach((modal) => {
        (state.modals as any)[modal].isOpen = false;
        (state.modals as any)[modal].props = null;
      });
    },
  },
});

export const {
  toggleShowSideMenu,
  closeActiveModals,
  setRedirectUrl,
  toggleExploreLayout,
  toggleExploreFilter,
  toggleModal,
} = uiSlice.actions;

export default uiSlice.reducer;
